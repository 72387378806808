import React from 'react';
import { graphql } from 'gatsby';

import Cours from './coursAdultes';

export default function Template({ data, pageContext }) {
  const { page } = data;
  const { couleur } = page;
  const title = `${page.nom}`;
  const { image_presentation } = page;
  const imgDesc = page.img_desc;
  const { icon } = page;
  const paragraphs = [
    {
      key: 'Horaires_et_Tarifs',
      name: 'Horaires et Tarifs',
      color: page.couleur.valeur,
    },
    {
      key: 'description_des_ateliers',
      name: 'Description des ateliers',
      color: page.couleur.valeur,
    },
  ];
  return (
    <Cours
      id={page.id}
      title={title}
      imgPresentation={image_presentation}
      icon={icon}
      imgDesc={imgDesc}
      paragraphs={paragraphs}
      color={page.couleur.valeur}
      days={page.jours}
      ateliers={page.description_des_ateliers}
      description={page.description}
      nota_bene={page.nota_bene}
      images={page.Images}
      detailsTarif={page.details_tarif}
      meta={page.meta}
      pageContext={pageContext}
    />
  );
}

export const pageQuery = graphql`
  query coursDessinPeintureQuery {
    site: site {
      siteMetadata {
        title
        description
        author
      }
    }
    page: strapiCoursDessinPeinture {
      id
      nom
      description
      nota_bene
      details_tarif
      couleur {
        valeur
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_desc {
        nom
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      description_des_ateliers {
        nom
        contenu
        id
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
        image_presentation {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
      }
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      Images {
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        nom
      }
      jours {
        jour
        horaires {
          description
          heure_debut
          heure_fin
          nom
          artiste {
            id
            nom
          }
          tarif {
            nom
            id
            tarifs {
              id
              nom
              valeur
            }
          }
        }
      }
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
            width
            height
            src
          }
          }
        }
      }
    }
  }
`;
